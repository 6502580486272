import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import loading_img from "../../../images/profile/loading.gif";
import { useParams, Router, useLocation } from "react-router-dom";
import { GlobalSet } from '../global/GlobalProvider';
//Import Components
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'
import nav_data from '../../layouts/nav/NavData';
import Slider from "react-slick";
import swal from "sweetalert";

const Home = () => {


const location = useLocation();
const urlSearchParams = new URLSearchParams(location.search);
const provider = urlSearchParams.get('provider');
let  providerGet;
if (provider !== undefined && provider !== null && provider !== '') {
   providerGet = provider;
}else{
   providerGet = "";
}
 
 

   const { id } = useParams();
   const { BaseUrl } = GlobalSet();
   const [homeitem, setHomeItem] = useState([]); 
   const [salider, setSalider] = useState([]);
   const [homeCasino, setCasino] = useState([]);   
   const var_casino= nav_data.casino;
   const [loading, setLoading] = useState(true);
   const [search, setQuery] = useState('');
 
 
  const [visibleItems, setVisibleItems] = useState(80); // Initial number of items to display
  const [totalItems, setcount_game] = useState([]);
  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const data = {
          key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
          slug: id,
          search: search,
          provider: providerGet,
        };

        const response = await axios.post('/casino-filters', data, {
          cancelToken: source.token,
        });

        setHomeItem(response.data.data);
        setcount_game(response.data.count_game);
        setSalider(response.data.slider);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled, ignore
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };
    // Only fetch data if the query is not empty
if (search.trim() !== '' || !loading) {
      fetchData();
    } else {
      // Clear data if the query is empty
      setHomeItem([]);
      setSalider([]);
      setCasino([]);
      setLoading(false);
    }

    // Cleanup function to cancel the request if the component unmounts
    return () => source.cancel();
  }, [id, search, loading]);

  const handleSeeMore = () => {
    // Increase the number of visible items when "See More" is clicked
    setVisibleItems(visibleItems + 80); // Adjust the number as needed
  };

//mobile odds option
  const casino_game_s = {
    infinite: true,
    arrows: true,
    speed: 100,
    slidesToShow: 10,
    slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1560,
                settings: {
                  slidesToShow: 8,
                  slidesToScroll: 7,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 7,
                  slidesToScroll: 5,
                },
            },  
            {
                breakpoint: 640,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 3,
                },
            },
        ],
  };

return(
<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">
      <div className="row">
      <Fragment>
          <Col xl="12" className="p-0" >
            <Card  >

            <Carousel className="p-0">
              {salider.map((carousel, i) => (
                <Carousel.Item key={i}>
                  <img
                    src={BaseUrl+(carousel.img_link)}
                    className='d-block w-100 mb-0'
                    alt={`Slide ${i + 1}`}
                  />
                </Carousel.Item>
              ))}
            </Carousel> 

                <div className="col-xl-12">
                    <div className="card-body pt-1 pb-2 rounded">
                      <Slider className="front-view-slider owl-carousel owl-carousel owl-loaded owl-drag owl-dot" {...casino_game_s}>   
                         {var_casino.map((product)=>(
                           <div className="p-1">  
                                <div>
                                  <Link to={ product.slug } className="text-black user-name">

                                         <div className="img-bx">
                                          <img style={{margin: "0px auto", width: "30%"}} src={BaseUrl + '/public/' + product.photo} alt="" className=" card-list-img w-10"/>
                                             <span className="nav-text text-center"  style={{display: "block"}}> {product.name}</span>
                                      </div>  
                                   </Link>
                                </div>  
                            </div>
                           ))}
                      </Slider>
                    </div>  
                </div>



                <div>
                  <div className="input-group  input-info">
                    <span className="input-group-text"><span className="fa fa-search"></span></span>
                    <input type="text" className="form-control" value={search} onChange={(e) => setQuery(e.target.value)} />
                    <span className="input-group-text">Search</span>
                  </div>
                </div>




              <Card.Body className="p-2" >
              <div className="form-inline">
                <div className="row">
                   {homeitem.slice(0, visibleItems).map((ele) => (
                      <div className="col-xl-2 col-xxl-3 col-lg-3 col-md-4 col-sm-6 col-6 int-col p-1">   
                            <div key={ele.id}>
                              <Link to={"/casino-game-get/"+ele.id+"/"+ele.slug} className="text-black user-name">

                                  <div className="img-bx">
                                      <img src={(ele.image)} alt="" className=" me-3 card-list-img w-100" width="130" />
                                  </div>  
                               </Link>
                            </div>  
                        </div>  
                        ))}
                </div>
              </div>

             {visibleItems < totalItems && (
                <div className="row text-center">
                  <div>
                    <button onClick={handleSeeMore} className="btn btn-primary mt-2">
                      See More > > > >
                    </button>
                   </div>  
                 </div>  
                )}

              </Card.Body>


            </Card>
          </Col>
      </Fragment>

      </div>
    </span>
    </>
  )
}
export default Home;