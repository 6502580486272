import React,{useEffect,useState} from "react";
import { Link } from "react-router-dom";
import { Redirect , useHistory} from "react-router-dom";
import swal from "sweetalert";
import { useLocation } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import logo from "../../../../images/logo-full.png";
import axios from "axios";

function Register(props) {

  const domain = window.location.hostname;
  const history = useHistory();
    const [loginInput, setLogin] = useState({
        email: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value });
    }

    const loginSubmit = (e) => {
        e.preventDefault();
        
        const data = {
            email: loginInput.email,
            root_url: domain,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }
            axios.post(`/forget-password`, data).then(res => {
                if(res.data.status === 'success')
                {
                    swal("Success",res.data.message,"success");
                    history.push('/');

                }
                else if(res.data.status === 'fail')
                {

                    swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setLogin({...loginInput, error_list: res.data.validation_error});
                }
            });
          }

  return (
    <div>
      <div className="container h-100 mt-1">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-8">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                     <img src={logo} alt="" />
                    </div>
                    <h4 className="text-center mb-2 ">Account Forgot Password </h4>
                        <form onSubmit={loginSubmit}>
                          <div className="form-group mb-3">
                             <div className="form-group">
                                <div className="form-group mb-3">
                                     <label className="mb-1 ">
                                        <strong>Enter Your Email Email</strong>
                                      </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                    name="email"
                                    onChange={handleInput} 
                                    value={loginInput.email}
                                    />
                                 </div>
                                <span>{loginInput.error_list.email}</span>
                                <div className="text-center mt-4">
                                    <button type="submit" className="btn btn-primary btn-block">
                                      Continue
                                    </button>
                                </div>
                            </div>
                          </div>
                      </form>
                      <div className="new-account mt-3">

                        <div class="container">
                          <div class="row">
                            <div class="col-6">
                              <p>Don't have an account? <Link className="text-primary" to="./register">Sign up</Link>
                                </p>
                            </div>
                            <div className="col-6">
                              <p style={{ float: "right" }}><Link className="text-primary" to="./">Go to home ?</Link>
                              </p>
                            </div>
                          </div>
                        </div>


                    </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};


export default Register ;

