import React,{useState ,useReducer, useEffect} from "react";
import { Link ,useHistory, useLocation} from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Image
import profile from "../../../images/user.jpg";
import avatar from "../../../images/logomob.png";
import LogoutPage from './Logout';
import PageTitle from "../../layouts/PageTitle";
import { GlobalSet } from '../../components/global/GlobalProvider';
import { Row, Card, Col, Button, Modal, Container, Dropdown ,Badge } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";

import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Header = ({ onNote }) => {
  const { CurrencySymbol } = GlobalSet();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const auth_token = localStorage.getItem('auth_token');
  const selectedCurrency = localStorage.getItem('selectedCurrency');


  // Check if ?login is in the URL, and open the modal
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("login")) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [location]);

  // Close the modal and remove ?login from the URL
  const handleClose = () => {
    setShow(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("login");
    history.replace({
      search: searchParams.toString(),
    });
  };

  // Open the modal by adding ?login to the URL
  const handleShowLogin = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("login", "true");
    history.replace({
      search: searchParams.toString(),
    });
  };

  const logoutSubmit = (e) => {
        e.preventDefault();
        axios.post(`/logout`).then(res => {
        	console.log(res.data.message)
            if(res.data.message === 'Unauthenticated')
            {
                localStorage.removeItem('auth_token');
                //swal("Success",res.data.message,"success");
                //history.push('/');
	             swal("Success", res.data.message, "success");
	             window.location.href = '/'; 
            }
        });
    }
      axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        if(err.response.status === 401)
        {
        	localStorage.removeItem('auth_token');
            swal("Unauthorized",err.response.data.message,"warning");
            history.push('/');
        }
        return Promise.reject(err);
    });

    const [loginInput, setLogin] = useState({
        username: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value });
    }

    const loginSubmit = (e) => {
        e.preventDefault();
        
        const data = {
            username: loginInput.username,
            password: loginInput.password,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }
            axios.post(`/apps-user-login`, data).then(res => {
                if(res.data.status === 'success')
                {
                    localStorage.setItem('auth_token', res.data.accessToken);
                    //swal("Success",res.data.message,"success");
                    //history.push('/');
                    swal("Success",res.data.message,"success");
	                window.location.href = '/'; 
 
                }
                else if(res.data.status === 'fail')
                {

                    swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setLogin({...loginInput, error_list: res.data.validation_error});
                }
            });
          }



   const [user, setUser] = useState(null);
   const [balance, setBalance] = useState(0); // Separate balance state
	 const fetchProfileData = () => {
	    const data = {
	      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
	      currency: selectedCurrency,
	    };
	    if (auth_token !== null) {
	      axios.post('/apps-profile', data)
	        .then(response => {
	          setUser(response.data);
	          setBalance(response.data?.data?.balance || 0); // Update balance
	        })
	        .catch(error => {
	          console.log(error);
	        });
	    }
	  };
	  useEffect(() => {
	    fetchProfileData();
	    const intervalId = setInterval(fetchProfileData, 5000);
	    return () => clearInterval(intervalId);
	  }, []);


var AuthButtons = '';
if(!localStorage.getItem('auth_token'))
{
  AuthButtons = (
	<ul className="navbar-nav header-right">
	    <div as="li" className="nav-item">
	        <Button variant="outline-primary btn-square btn-xxs" onClick={handleShowLogin}>
	            <strong>Login</strong>
	        </Button>
	    </div>
	    <Modal show={show} onHide={handleClose}>
	        <Modal.Header closeButton>
	            <Modal.Title>Login Here</Modal.Title>
	        </Modal.Header>
	         <Modal.Body>
	            <form onSubmit={loginSubmit}>
		            <div className="form-group mb-3">
		               <div className="form-group">
		                  <div className="form-group mb-3">
		                       <label className="mb-1 ">
		                          <strong>Username</strong>
		                        </label>
		                    <input
		                      type="text"
		                      className="form-control"
		                      placeholder="username"
		                       name="username"
		                     onChange={handleInput} 
		                     value={loginInput.username}
		                      />
		                   </div>
		                  <span>{loginInput.error_list.username}</span>
		                 

                        <label className="text-label">Password</label>
                        <div className="input-group transparent-append mb-2">
                          
                            <span className="input-group-text">
                              {" "}
                              <i className="fa fa-lock" />{" "}
                            </span>
                          
                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            id="val-password1"
                            placeholder="password"
                            name="password"
                            onChange={handleInput} 
                            value={loginInput.password}
                          />

                          <div
                            className="input-group-text "
                            onClick={() => setShowPassword(!showPassword)}
                          >

                              {" "}
								  {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                            
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                          </div>


                        </div>
		                 <span>{loginInput.error_list.password}</span>



		                  <div className="text-center mt-4">
		                      <button type="submit" className="btn btn-primary btn-block">
		                        Login
		                      </button>
		                  </div>
		              </div>
		            </div>
	        	</form>
	        	<div className="new-account mt-3">
					<p><span>New account? <Link className="text-primary" to="./register">Sign up</Link></span>
					<span style={{ float: 'right' }}><Link className="text-primary" to="./forgot-password">Forgot Password ?</Link></span>
					</p>
				</div>
	        </Modal.Body>
	    </Modal>
	    <Dropdown as="li" className="nav-item">
		    <Link to="/register" >
				<Button variant="outline-primary btn-square btn-xxs" >
				    <strong>signup</strong>
				</Button>
			</Link>
		</Dropdown>
	</ul>
   );
    }
    else
    {
  AuthButtons = (
	<ul className="navbar-nav header-right">
	


		<Dropdown as="li" className="nav-item dropdown header-profile">

        <button type="button" className="me-2 btn btn-dark btn-rounded">
          <Link to="/deposit" className="btn-icon-start text-primary" style={{ textDecoration: 'none' }}>
            <i className="fa fa-plus color-info"></i>
          </Link>
          <span className="btn-icon-start text-primary" onClick={fetchProfileData} >
            <i className="ti-reload"></i>
          </span>
          {CurrencySymbol} {parseFloat(parseFloat(balance).toFixed(2))} 
        </button>

			<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
				<img src={profile} width={20} alt="" />
			</Dropdown.Toggle>
			<Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">

				<Link to="/app-profile" className="dropdown-item ai-icon">
					<svg
					  id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary me-1"
					  width={18} height={18} viewBox="0 0 24 24" fill="none"
					  stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
					  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
					  <circle cx={12} cy={7} r={4} />
					</svg>
					<span className="ms-2"> {t('he_pro_profile')} </span>
				</Link>
				<Link to="/mybet-history" className="dropdown-item ai-icon">
				    <svg 
					    xmlns="http://www.w3.org/2000/svg" 
					    className="text-primary me-1" width={18} height={18} 
					    viewBox="0 0 24 24" fill="none" stroke="currentColor" 
					    strokeWidth={2} stroke-linecap="round" stroke-linejoin="round">
					    <line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6"
					    x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18">
					    </line>
					</svg>
				    <span className="ms-2"> {t('he_pro_bet_his')} </span>
				</Link>
				<Link to="/income-history" className="dropdown-item ai-icon">
				    <svg 
			            xmlns="http://www.w3.org/2000/svg" 
			            className="text-primary me-1"
				        width={18} height={18} viewBox="0 0 24 24" fill="none" 
				        stroke="currentColor" strokeWidth={2} stroke-linecap="round" 
				        stroke-linejoin="round"><line x1="18" y1="20" x2="18" y2="10">
				        </line><line x1="12" y1="20" x2="12" y2="4"></line><line x1="6"
				        y1="20" x2="6" y2="14"></line>
					</svg>
					<span className="ms-2"> {t('he_pro_inc_his')} </span>
				</Link>
				<Link to="/deposit" className="dropdown-item ai-icon">

				<svg xmlns="http://www.w3.org/2000/svg" className="text-primary me-1" 
				width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="4" width="20" height="16" rx="2"/><path d="M7 15h0M2 9.5h20"/></svg>


					<span className="ms-2"> {t('he_pro_dep_his')} </span>
				</Link>				
				 <Link to="/transaction-history" className="dropdown-item ai-icon">
				    <svg 
				        xmlns="http://www.w3.org/2000/svg" className="text-primary me-1"
					    width={18} height={18} viewBox="0 0 24 24" fill="none" 
					    stroke="currentColor" strokeWidth={2} stroke-linecap="round" 
					    stroke-linejoin="round"><path d="M18 3a3 3 0 0 0-3 3v12a3 3 0 0 
					    0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 
					    3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 
					    3 0 0 0 3-3 3 3 0 0 0-3-3z"></path>
					</svg>
					<span className="ms-2"> {t('he_pro_tra_his')} </span>
				</Link>
				<Link to="/withdraw" className="dropdown-item ai-icon">

					<svg xmlns="http://www.w3.org/2000/svg" className="text-primary me-1"
						width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 6 2 18 2 18 9"></polyline><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg>

					<span className="ms-2"> {t('he_pro_with_his')} </span>
				</Link>
				<Link to="/security" className="dropdown-item ai-icon">
				<svg xmlns="http://www.w3.org/2000/svg" className="text-primary me-1"
				width={18} height={18}  viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
					<span className="ms-2"> {t('he_pro_sec')} </span>
				</Link>
				<Link to="/support-ticket" className="dropdown-item ai-icon">
				<svg xmlns="http://www.w3.org/2000/svg" className="text-primary me-1"
				width={18} height={18}  viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
					<span className="ms-2"> {t('support_ticket')} </span>
				</Link>
				<Link to="" className="dropdown-item ai-icon">
				    <svg 
				        xmlns="http://www.w3.org/2000/svg" className="text-primary me-1"
				        width={18} height={18} viewBox="0 0 24 24" fill="none" 
				        stroke="currentColor" strokeWidth={2} stroke-linecap="round" 
				        stroke-linejoin="round"><path d="M16 17l5-5-5-5M19.8 12H9M10 
				        3H4v18h6"/>
				    </svg>
					<span onClick={logoutSubmit} className="ms-2"> {t('he_pro_logout')} </span>
				</Link>
			</Dropdown.Menu>
		</Dropdown>
	</ul>
        );
    }

const [searchBut, setSearchBut] = useState(false);	
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  return ( 
    <div style={{ zIndex: 998 }} className="header border-bottom">
        <div className="header-content">
		    <nav className="navbar navbar-expand">
		      <div className="collapse navbar-collapse justify-content-between">
		        <div className="header-left">
					<div
						className="dashboard_bar"
						style={{ textTransform: "capitalize" }}
					  >
						{finalName.join(" ").length === 0
						  ? "EkBet"
						  : finalName.join(" ") === "dashboard dark"
						  ? "EkBet"
						  : finalName.join(" ")}
					</div>
		       </div>

			<div className="nav-item d-flex align-items-center">
				<div className="input-group search-area">
					<input type="text" 
						className={`form-control ${searchBut ? "active" : ""}`}
						placeholder="Search.." 
					/>
					<span className="input-group-text" onClick={() => setSearchBut(!searchBut)}>
						<Link to={"#"}><i className="flaticon-381-search-2"></i></Link>
					</span>
				</div>
			</div> 


		        <ul className="navbar-nav header-right">

					 {AuthButtons}
			    </ul>
		      </div>
		    </nav>
        </div>
    </div>
  );
};

export default Header;
