import React, { Fragment,useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import { Link , useLocation , useHistory } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import logo from "../../../../images/logo-full.png";
import axios from "axios";
import swal from "sweetalert";

const Checkout = () => {
  const [depositInput, setDepositInput] = useState({
      new_password: '',
      new_confirm_password: '',
      error_list: [],
  });

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const codeValue = urlSearchParams.get('code');
  const history = useHistory();

    const handleInput = (e) => {
        e.persist();
        setDepositInput({...depositInput, [e.target.name]: e.target.value });
    }


    useEffect(()=>{
        
      const data = {
        key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        codeValue: codeValue,
      }
        axios.post('/forget-check-valid',data)
        .then(response=>{
                if(response.data.status === 'success')
                {
                }
                else if(response.data.status === 'fail')
                {
                  swal("Warning",response.data.message,"warning");
                  history.push('/login');
                }
                else 
                {
                  swal("Warning",response.data.message,"warning");
                  history.push('/login');
                }
           
         })
     .catch(error=>{
            
           console.log(error)
       })

   },[]);


    const depositSubmit = (e) => {
        e.preventDefault();
        const data = {
            new_password: depositInput.new_password,
            new_confirm_password: depositInput.new_confirm_password,
            codeValue: codeValue,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
           
        }
          axios.post(`/forget-password-change`, data).then(res => {
                if(res.data.status === 'success')
                {
                   swal("Success",res.data.message,"success");
                  setDepositInput({...depositInput,
                    new_password:'',
                    new_confirm_password:'',
                    
                  });
                    history.push('/');
                }
                else if(res.data.status === 'fail')
                {
                  swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setDepositInput({...depositInput, error_list: res.data.validation_error});
                }
            });
       

          }



   return (
      <Fragment>
    <div>
      <div className="container h-100 mt-4">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-8">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                     <img src={logo} alt="" />
                    </div>      
                     <div className="row">
                        <div className="col-xl-12">
                           <div className="card">
                              <div className="card-body">
                                 <div className="row">
                                    <div className="col-md-12 order-md-1">
                                       <h4 className="mb-3">Password Change</h4>
                                       <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                                          <div className="row">
                                             <div className="col-md-12 mb-3">
                                                <label htmlFor="firstName">
                                                   New Password
                                                </label>
                                                <input
                                                   type="password"
                                                   className="form-control"
                                                   id="firstName"
                                                   placeholder=""
                                                   required
                                                   name="new_password"
                                                   onChange={handleInput} 
                                                   value={depositInput.new_password}
                                                />
                                                   <span className="text-danger">{depositInput.error_list.new_password}</span>
                                                
                                             </div>

                                             <div className="col-md-12 mb-3">
                                                <label htmlFor="firstName">
                                                   New Confirm Password
                                                </label>
                                                <input
                                                   type="password"
                                                   className="form-control"
                                                   id="firstName"
                                                   placeholder=""
                                                   required
                                                   name="new_confirm_password"
                                                   onChange={handleInput} 
                                                   value={depositInput.new_confirm_password}
                                                />
                                               <span className="text-danger">{depositInput.error_list.new_confirm_password}</span>
                                                
                                             </div>
                                          </div>

                                          <hr className="mb-4" />
                                          <button
                                             className="btn btn-primary btn-lg btn-block"
                                             type="submit"
                                          >
                                             Change Password
                                          </button>
                                       </form>
                                        <div className="new-account mt-3">
                                          <div class="container">
                                            <div class="row">
                                              <div class="col-6">
                                                <p>Don't have an account? <Link className="text-primary" to="./register">Sign up</Link>
                                                  </p>
                                              </div>
                                              <div className="col-6">
                                                <p style={{ float: "right" }}><Link className="text-primary" to="./login">Go To Login ?</Link>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      </Fragment>
   );
};

export default Checkout;
