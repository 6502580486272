import { lazy, Suspense, useEffect, useState } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import {  Route, Switch, withRouter, useHistory,useLocation } from 'react-router-dom';
// action

import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import axios from "axios";
import swal from "sweetalert";
import { GlobalProvider } from './jsx/components/global/GlobalProvider';

axios.interceptors.request.use(function (config){
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

function App (props) {

function clearAllCaches() {
    localStorage.clear();
    sessionStorage.clear();
    if ('caches' in window) {
        caches.keys().then(cacheNames => {
            cacheNames.forEach(cacheName => {
                caches.delete(cacheName);
            });
        });
    }
}
setTimeout(clearAllCaches, 10 * 24 * 60 * 60 * 1000); // 10 days in milliseconds

// Online state
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  if(!isOnline){
       swal("You Are Offline !!","Check Your Internet Connection");
  }

  const auth_token = localStorage.getItem('auth_token');
  const history = useHistory();
  useEffect(() => {
    const path = window.location.pathname;
    if (auth_token) {
            const data = {
                key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            }
            if (auth_token !== null) {

                axios.post(`/checkinAuthenticated`, data).then(res => {
                    if(res.data.status === 'success')
                    {
                      if (path === "/register") {
                        history.push('/');
                      }
                    }
                    else if(res.data.status === 'fail')
                    {
                      localStorage.removeItem('auth_token');
                      swal("Please login Again",res.data.message,"warning");
                     history.push('/');
                    }
                   else 
                    {
                      localStorage.removeItem('auth_token');
                      swal("Please login Again","","error");
                     history.push('/');
                    }
                });
              }
              else 
              {
               localStorage.removeItem('auth_token');
                swal("Please Login Again","","error");
                     history.push('/');
              }

    } else {
     history.push(path+'?'+urlSearchParams);
    }
  }, [history]);

    return (
      <>
        <GlobalProvider>
             <Suspense fallback={
                <div id="preloader">
                </div>  
               }>
                <Index / >
            </Suspense>
        </GlobalProvider>
      </>
    );
};
export default withRouter(App); 

